body {
  background-image: url('../images/players/Aris-Atletiko2010.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  margin: 0;
  position: relative; /* Ensure the pseudo-element covers the body */
}

body::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8); /* Black overlay */
  pointer-events: none; /* Allows interaction with elements behind the overlay */
  z-index: -1; /* Ensures overlay stays in the background */
}


.home-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* Ensure it takes the full viewport height */
  width: 100%; /* Ensure it takes the full width */
  padding: 20px; /* Add some padding for small screens */
  box-sizing: border-box; /* Include padding in width calculation */
}

.home-card {
  background: rgba(0, 0, 0, 0.8);
  padding: 30px;
  border-radius: 10px;
  text-align: center;
  max-width: 600px;
  color: white;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
}

h1 {
  font-size: 3em;
  color: #ffd700;
  margin: 0 0 10px 0;
}

h2 {
  font-size: 1.5em;
  margin: 0 0 20px 0;
}

.site-concept {
  font-size: 1.1em;
  line-height: 1.5;
  margin-bottom: 30px;
}

/* Container for the buttons */
.home-buttons {
  display: flex;
  justify-content: center; /* Center the buttons */
  gap: 10px; /* Space between buttons */
  margin-top: 20px; /* Add some space above the button container if needed */
}

/* Individual button styles */
.home-button {
  background-color: #ffeb3b; /* Bright yellow background */
  color: #000; /* Black text color */
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  text-decoration: none; /* Remove underline */
  display: inline-block;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2); /* Add shadow for a more modern look */
  transition: all 0.3s ease; /* Smooth transition for hover effect */
}

.home-button:hover {
  background-color: #ffe066; /* Slightly lighter yellow on hover */
  transform: translateY(-2px); /* Slight lift effect on hover */
}



.logo {
  background-image: url('../images/aris11.png'); /* Ensure this path is correct */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 50%; /* Use the full width of its container */
  min-width: 200px; /* Force a minimum width */
  height: 200px; /* Set height */
  min-height: 200px; /* Force a minimum height */
  display: block;
  margin: 0 auto 20px; /* Center the logo horizontally */
}
