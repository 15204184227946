body, html, #root, .App {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

.App {
  text-align: center;
  position: relative;
  z-index: 2;
}

.full-page-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('./images/Aris-Atletiko2010.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 1;
}

.App-header h1 {
  font-family: 'Roboto', sans-serif; /* Use a modern font */
  font-weight: 400; /* Make the font less bold */
  font-size: 24px; /* Adjust the font size */
  color: #ffeb3b; /* Modern dark gray color */
  letter-spacing: 1px; /* Adds slight spacing */
  text-transform: none; /* Normal case */
}

.App-header, .container {
  position: relative;
  z-index: 2;
  color: #ffeb3b;
}

.App-header {
  background-color: rgba(0, 0, 0, 0.8);
  padding: 20px;
}

.container {
  background-color: rgba(0, 0, 0, 0.7);
  padding: 20px;
  border: 1px solid #ffeb3b;
}

.formation-selector {
  margin: 20px 0;
  text-align: left;
}

.formation-label {
  font-size: 1.2em; /* Slightly larger font size */
  color: #ffeb3b;
  margin-right: 10px;
}

.select-dropdown {
  background-color: #000;
  color: #ffeb3b;
  border: 1px solid #ffeb3b;
  padding: 5px;
  font-size: 1em;
}

.field {
  position: relative;
  width: 70%;
  padding: 20px;
  margin: auto;
  background-color: rgba(0, 128, 0, 0.8);
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.position {
  display: inline-block;
  margin: 13px;
  width: 55px;
  height: 55px;
  background-color: #fff;
  border: 1px solid #ffeb3b;
  color: #000;
  text-align: center;
  line-height: 50px;
}

.roster {
  background-color: #000;
  color: #ffeb3b;
  border: 1px solid #ffeb3b;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.player {
  display: block;
  margin: 5px 0;
  padding: 10px;
  background-color: #ffeb3b;
  color: #000;
  border: 1px solid #000;
  border-radius: 3px;
  cursor: pointer;
  text-align: center;
}

.player:hover {
  background-color: #000;
  color: #ffeb3b;
  border-color: #ffeb3b;
}

/* Text above badges */
.next-match-text {
  font-size: 22px;
  color: #ffd700; /* Aris yellow */
  text-align: center;
  margin-bottom: 10px; /* Space between this text and badges */
  font-weight: bold;
}

/* Container for the overall header layout */
.header-layout {
  display: flex;
  margin-top: 5px;
  align-items: flex-start; /* Align everything to the top */
  justify-content: flex-start; /* Align everything to the left */
  flex-direction: column;
  padding-left: 10px; /* Add padding to the left to avoid touching the edge */
}

/* Adjust the next-match text */
.next-match-text {
  font-size: 18px;
  color: #ffd700;
  margin-bottom: 5px;
  margin-top: -10px; /* Keep the position of "Επόμενος Αγώνας" */
}

/* Match info section */
.match-info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 40px; /* Move the badge section to the right */
  margin-bottom: 5px;
}

/* Team badges styling */
.team-badge {
  width: 100px; /* Badge size */
  height: auto;
  margin-right: 10px;
}

.opponent-badge {
  margin-left: 20px; /* Slight space between "VS" and opponent badge */
}

/* Countdown text */
.countdown {
  margin-left: 55px; /* Align it with the rest of the content */
  margin-bottom: 35px;
  color: #ffd700;
  font-size: 17px;
}

/* Stadium info container */
.stadium-info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 40px; /* Align it with the rest of the content */
}

/* Small stadium icon */
.stadium-icon {
  width: 50px;
  height: auto;
  margin-right: 5px;
}

/* Stadium name */
.stadium-name {
  font-size: 17px;
  color: #ffd700;
  font-weight: bold;
}

/* Lineup instructions */
.lineup-instructions {
  position: absolute;
  margin-top:35px;
  top: 20px; /* Adjust as needed */
  right: 40px; /* Adjust as needed */
  color: #ffd700; /* Aris yellow */
  font-size: 30px;
  font-weight: bold;
  z-index: 10; /* Ensures the text is visible over other elements */
}

.logout-button {
  position: absolute;
  margin-top: -380px;
  right: 10px;
  background-color: #ff0000; /* Red background */
  border: none;
  padding: 5px 10px;
  color: #000; /* Black text */
  cursor: pointer;
  border-radius: 5px;
  font-weight: bold;
}

.logout-button:hover {
  background-color: #ff3333; /* Darker red when hovered */
}

.logo-container {
  display: flex;
  justify-content: center; /* Centers horizontally */
  align-items: center; /* Centers vertically if needed */
  width: 100%; /* Makes sure the container spans the entire width */
  margin-top: 0px; /* Adjust as needed */
}

.center-logo {
  height: 100px; /* Adjust as needed */
  width: auto;
}
