/* Set up a full-page background */
body {
  background-image: url('../images/players/Aris-Atletiko2010.jpg'); /* Correct path */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Arial, sans-serif; /* Set a global font */
}

/* Auth.css */

/* Include Google Font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

/* Apply the font to this component */
body {
    font-family: 'Poppins', sans-serif;
}


.auth-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* Ensure it takes the full viewport height */
  width: 100%; /* Ensure it takes the full width */
  padding: 20px; /* Add some padding for small screens */
  box-sizing: border-box; /* Include padding in width calculation */
}

/* Styling for the auth card */
.auth-card {
  background: linear-gradient(145deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.6)); /* Gradient background */
  padding: 40px; /* Add more padding */
  border-radius: 15px; /* Softer rounded corners */
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.4); /* Stronger shadow for more depth */
  color: white;
  text-align: center;
  max-width: 450px; /* Slightly wider for better readability */
  width: 100%; /* Make sure it doesn't exceed the screen on small devices */
  box-sizing: border-box; /* Include padding in width calculation */
}



/* Styling for the form */
.auth-form {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the form elements */
}

/* Consistent and responsive input fields */
.auth-input {
  width: 100%; /* Full width of the container */
  max-width: 350px; /* Set a max-width for better appearance */
  margin: 10px 0;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px; /* Slightly larger font for better readability */
  color: black;
  box-sizing: border-box; /* Include padding in width calculation */
}

/* Styling for buttons */
.auth-button {
  width: 100%; /* Full width of the container */
  max-width: 350px; /* Set a max-width for better appearance */
  background-color: #ffeb3b;
  color: #000;
  border: none;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

/* Hover effect for buttons */
.auth-button:hover {
  background-color: #ffd700;
}

/* Styling for error messages */
.auth-error {
  color: red;
  margin-top: 10px;
  text-align: center;
}

/* Auth.css */
.logo {
  background-image: url('../images/aris11.png'); /* Ensure this path is correct */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 50%; /* Use the full width of its container */
  min-width: 150px; /* Force a minimum width */
  height: 200px; /* Set height */
  min-height: 150px; /* Force a minimum height */
  display: block;
  margin: 0 auto 20px; /* Center the logo horizontally */
}



/* Styling for the forgot password button */
.forgot-password-link {
  display: inline-block; /* Display as inline-block for proper margin */
  margin-top: 15px; /* Space from the login button */
  color: #000; /* Black text */
  background-color: #ffeb3b; /* Yellow background */
  border: none;
  border-radius: 5px;
  padding: 5px 10px; /* Smaller padding */
  font-size: 14px; /* Smaller font size */
  cursor: pointer;
  text-align: center;
  text-decoration: none; /* Remove underline */
  transition: background-color 0.3s ease; /* Smooth transition on hover */
}

/* Hover effect for modern look */
.forgot-password-link:hover {
  background-color: #ffd700; /* Slightly darker yellow on hover */
  color: #333; /* Darker text on hover */
}


/* Styling for the forgot password section */
.forgot-password-section {
  display: flex;
  justify-content: center;
  gap: 10px; /* Space between the fields */
  margin-top: 20px;
}

/* Input fields for the forgot password section */
.forgot-password-input {
  flex: 1; /* Make sure each input takes equal space */
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 14px;
  margin-top: 10px; /* Add some top margin for spacing */
}

/* Submit button for forgot password section */
.forgot-password-button {
  width: 100%;
  max-width: 120px;
  background-color: #ffeb3b;
  color: #000;
  border: none;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
}

/* Responsive adjustments for small screens */
@media (max-width: 500px) {
  .auth-card {
    padding: 20px; /* Reduce padding on smaller screens */
  }

  .auth-input, .auth-button, .forgot-password-input, .forgot-password-button {
    max-width: 100%; /* Ensure full width on smaller screens */
  }

  .forgot-password-section {
    flex-direction: column; /* Stack the inputs on smaller screens */
  }
}

.auth-secondary-button {
  background-color: #FFD700; /* Yellow color */
  color: black;
  border: none;
  padding: 5px 15px; /* Reduced padding for a slimmer button */
  font-size: 14px;
  cursor: pointer;
  margin-top: 20px; /* Moves the button further down */
  border-radius: 4px; /* Optional: adds a subtle rounded edge */
}

.auth-secondary-button:hover {
  background-color: #FFC300; /* Slightly darker yellow on hover */
}
