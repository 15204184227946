.field-container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  height: 100vh;
  box-sizing: border-box;
}

.sidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
}

.field {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid white; /* White border for the field outline */
  width: 80%;
  height: 100%;
  background-color: green;
  box-sizing: border-box;
  border-radius: 20px;
}

.half-circle {
  position: absolute;
  top: 0; /* Align it at the top */
  left: 50%; /* Center it horizontally */
  width: 220px; /* Adjust width as needed for proper sizing */
  height: 110px; /* Half of the width for a perfect semi-circle */
  background-color: transparent; /* No background */
  border: 5px solid white; /* Uniform thickness for the border */
  border-bottom: none; /* No bottom border */
  border-radius: 200px 200px 0 0; /* Create the rounded effect */
  transform: translateX(-50%) rotate(180deg); /* Center it and flip the arc */
}

.penalty-box {
  position: absolute;
  top: 73.5%; /* Adjust to position the penalty box */
  left: 50%; /* Center it horizontally */
  width: 370px; /* Adjust the width */
  height: 130px; /* Adjust the height */
  border: 5px solid white; /* Same thickness as the half-circle */
  border-bottom: none; /* No bottom border */
  transform: translateX(-50%);
}

.small-box {
  position: absolute;
  top: 91%; /* Adjust the placement */
  left: 50%;
  width: 150px;
  height: 42px;
  border: 5px solid white;
  border-bottom: none;
  transform: translateX(-50%);
}

.small-half-circle {
  position: absolute;
  top: 64%; /* Adjust based on where you want the circle to appear */
  left: 50%;
  width: 90px; /* Increase the width for a wider circle */
  height: 45px; /* Adjust height for proper proportion */
  border: 4px solid white;
  border-bottom: none;
  border-radius: 200px 200px 0 0;
  transform: translateX(-50%) ;
}


.formation {
  display: grid;
  grid-template-rows: repeat(4, 1fr);
  width: 100%;
  height: 100%;
  gap: 10px;
  align-items: center;
  justify-items: center;
}

.row {
  display: grid;
  width: 100%;
  gap: 10px;
  align-items: center;
  justify-items: center;
}

.row-0 {
  grid-template-columns: repeat(2, 1fr);
}

.row-1 {
  grid-template-columns: repeat(4, 1fr);
}

.row-2 {
  grid-template-columns: repeat(4, 1fr);
}

.row-3 {
  grid-template-columns: 1fr;
  justify-items: center;
}

/* Adjustments for 4-3-3 */
.row-0-433 {
  grid-template-columns: repeat(3, 1fr);
}

.row-1-433 {
  grid-template-columns: repeat(3, 1fr);
}

.row-2-433 {
  grid-template-columns: repeat(4, 1fr);
}

.row-3-433 {
  grid-template-columns: 1fr;
  justify-items: center;
}

.row {
  display: flex;
  justify-content: center; /* Center the players horizontally */
  margin-bottom: 10px; /* Space between rows */
  gap: 50px;
}

.row-2-343 { /* For the 3 CBs in the 3-4-3 formation */
  justify-content: center;
  gap: 50px; /* You can adjust this gap to change the spacing between the CBs */
}

.row-0-343, .row-1-343 { /* Center and space evenly for forwards and midfielders */
  justify-content: space-around;
}

.row-3-343 { /* For the GK */
  justify-content: center;
}



.position {
  background: linear-gradient(135deg, #ffeb3b 0%, #fdd835 100%); /* Yellow gradient */
  width: 85px;
  height: 85px;
  border-radius: 10px; /* Rounded corners for a better look */
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  transition: transform 0.2s ease; /* Smooth transition */
  z-index: 10; /* Make sure positions stay above the white lines */
}

.position-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.position:hover {
  transform: scale(1.05); /* Slight enlarge on hover */
  background-color: #e6e6e6; /* Light gray on hover */
}



.player-image {
  width: 78px; /* Slightly smaller than the container */
  height: 78px;
  object-fit: cover;
  border-radius: 8px; /* Match the corner radius of the container */
  position: relative;
  z-index: 1; /* Ensures the image is above the yellow box */
}

.player-position {
  background-color: #f1cc12;
  color: black;
  font-size: 12px;
  font-weight: bold;
  padding: 2px 6px;
  border-radius: 3px;
  margin-left: 10px;
}

.player-name {
  font-size: 14px; /* Smaller font size to fit better */
  color: rgb(0, 0, 0); /* Match with the overall theme */
  text-align: center;
  margin-top: 5px;
  line-height: 1.2;
}


.player-ratings {
  position: absolute;
  bottom: -40px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px;
  border-radius: 3px;
  display: none;
}

.player:hover .player-ratings {
  display: flex;
  justify-content: space-between;
  width: 80px;
}

.player-ratings span {
  font-size: 10px;
  margin: 0 3px;
}


.roster {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 15px;
  height: 100%;
  overflow-y: auto;
  padding: 10px;
  box-sizing: border-box;
}

.roster h3 {
  margin-bottom: 10px;
}

.roster-and-button {

  margin-left: 30px;  /* This moves the whole roster-and-button div to the right */
}

/* Full-page message styles */
.full-page-message {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Make sure it's on top of other elements */
}

.message-box {
  background: #ffeb3b; /* Yellow background */
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  max-width: 80%;
  color: #000; /* Change text color to black for contrast */
  font-size: 18px; /* Increase the font size for better readability */
}

.ok-button {
  margin-top: 20px;
  padding: 10px 20px;
  background: #000;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.ok-button:hover {
  background: #333;
}


.small-button {
  font-size: 18px;
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid #ffeb3b;
  background-color: #000;
  color: #ffeb3b;
  cursor: pointer;
}

.small-button:hover {
  background-color: #333;
  color: #fcfcfc;
}

.remove-button {
  position: absolute;
  top: -15px; /* Move it further up */
  right: -10px; /* Keep it slightly right */
  background-color: red;
  color: white;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  padding: 2px 6px; /* Adjust padding for proper size */
  font-size: 14px; /* Ensure the font size fits well */
  line-height: 1; /* Adjust line height */
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bottom-right-buttons {
  position: absolute;
  bottom: 20px; /* Adjust spacing from the bottom for a more balanced look */
  right: 20px; /* Added spacing from the right side */
  display: flex;
  gap: 15px; /* More space between buttons */
}

.bottom-right-buttons button {
  background-color: #ffeb3b; /* Bright yellow */
  border: 2px solid #fdd835; /* Slight border for a modern feel */
  padding: 12px 24px; /* Balanced padding for a larger, click-friendly size */
  color: #333; /* Darker text color for better contrast */
  font-weight: bold;
  font-family: 'Roboto', sans-serif; /* Clean and modern font */
  font-size: 16px; /* Slightly larger font for readability */
  cursor: pointer;
  border-radius: 8px; /* Softer rounded corners */
  transition: all 0.3s ease; /* Smooth transition for hover effects */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
}

.bottom-right-buttons button:hover {
  background-color: #fdd835; /* Darker yellow on hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
  transform: translateY(-3px); /* Button lifts slightly on hover */
}

.bottom-right-buttons button:active {
  transform: translateY(0); /* Button returns to original position when clicked */
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15); /* Slightly reduced shadow on click */
}



/* Tooltip Styling */
.tooltip-inner {
  background-color: rgba(0, 0, 0, 0.8) !important;
  color: white !important;
  padding: 5px !important;
  border-radius: 3px !important;
}

.tooltip-arrow {
  border-top-color: rgba(0, 0, 0, 0.8) !important;
}

.react-tooltip {
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 5px;
  border-radius: 4px;
  font-size: 12px;
  z-index: 1000;
}

