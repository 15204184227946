/* src/components/TiltNotification.css */
.tilt-notification {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    z-index: 1000;
  }
  
  .icon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .phone-icon {
    font-size: 60px;
  }
  
  .rotate-arrow {
    display: inline-block;
    font-size: 40px;
    animation: rotate 2s infinite linear;
  }
  
  @keyframes rotate {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  