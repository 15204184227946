/* Styling for the overall container */
.under-construction-container {
    position: relative;
    width: 100vw;
    height: 100vh;
    background-image: url('./images/Aris-Atletiko2010.jpg');
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Create a black overlay with transparency */
  .under-construction-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Adjust opacity to make text stand out */
    z-index: 1;
  }
  
  /* Ensure the content is on top of the overlay */
  .overlay {
    position: relative;
    z-index: 2;
    text-align: center;
    color: white; /* White text to contrast with black background */
  }
  
  /* Styling for the logo */
  .logo {
    width: 150px; /* Adjust the size as needed */
    margin-bottom: 20px; /* Adds space between the logo and text */
  }
  
  /* Text styles */
  h1 {
    font-size: 3rem; /* Adjust size as needed */
  }
  
  p {
    font-size: 1.2rem; /* Adjust size as needed */
  }
  