.leaderboard-container {
    margin: 20px auto !important;
    padding: 20px !important;
    max-width: 800px !important;
    background-color: #1e1e1e !important;
    border-radius: 15px !important;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3) !important;
    color: #f0f0f0 !important;
    overflow: hidden !important;
}

.leaderboard-container h2 {
    text-align: center !important;
    margin-bottom: 25px !important;
    color: #ffc107 !important;
    font-size: 1.8rem !important;
    text-shadow: 0 2px 5px rgba(0, 0, 0, 0.5) !important;
}

.leaderboard-table {
    width: 100% !important;
    border-collapse: collapse !important;
    overflow: hidden !important;
}

.leaderboard-table th {
    background: linear-gradient(135deg, #444, #333) !important;
    color: #ffcc00 !important;
    padding: 12px !important;
    text-align: center !important;
    border-bottom: 2px solid #555 !important;
    font-size: 1rem !important;
}

.leaderboard-table td {
    padding: 12px !important;
    text-align: center !important;
    border-bottom: 1px solid #555 !important;
    font-size: 0.95rem !important;
}

.leaderboard-table tr:nth-child(odd) {
    background-color: #252525 !important;
}

.leaderboard-table tr:nth-child(even) {
    background-color: #2b2b2b !important;
}

.leaderboard-table tr:hover {
    background-color: #3d3d3d !important;
    color: #ffc107 !important;
    transition: background-color 0.3s, color 0.3s !important;
}

.leaderboard-table .rank-1 td {
    background-color: #ffd700 !important;
    color: #000 !important;
    font-weight: bold !important;
}

.leaderboard-table .rank-2 td {
    background-color: #c0c0c0 !important;
    color: #000 !important;
    font-weight: bold !important;
}

.leaderboard-table .rank-3 td {
    background-color: #cd7f32 !important;
    color: #000 !important;
    font-weight: bold !important;
}

/* Adding a subtle box shadow to the top 3 for better visual distinction */
.leaderboard-table .rank-1 td,
.leaderboard-table .rank-2 td,
.leaderboard-table .rank-3 td {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3) !important;
}
