/* Main container layout */
.statistics-section {
  flex: 1;
  margin-right: 20px;
  padding: 20px;
  background: rgba(0, 0, 0, 0.6); /* Transparent background */
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  height: 100%; /* Stretch to full height */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* Player Statistics table */
.statistics-table {
  width: 100%;
  border-collapse: collapse;
  font-family: 'Poppins', sans-serif; /* Use the same font */
}

.statistics-table th, .statistics-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2); /* Subtle borders */
}

.statistics-table th {
  background-color: #ffd700; /* Aris yellow */
  color: #333;
  text-transform: uppercase;
  font-weight: bold;
}

.statistics-table td {
  background-color: rgba(255, 255, 255, 0.1); /* Subtle background */
  color: #fff; /* White text */
}

.statistics-table tr:hover {
  background-color: rgba(255, 255, 255, 0.2); /* Hover effect */
}

/* Player info - image and text alignment */
.player-info {
  display: flex;
  align-items: center;
}

.player-info img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover; /* Ensure the image fits the container */
}

.player-info span {
  font-size: 14px;
  color: #fff; /* White text for consistency */
}

/* Hover effect for table rows */
.statistics-table tr:hover {
  background-color: rgba(255, 255, 255, 0.2); /* Smooth hover effect */
}

/* Ensure full height for the statistics section */
.statistics-section {
  min-height: 100%;
}

/* Consistency with other sections */
.statistics-section h2 {
  color: #ffd700; /* Aris yellow */
  text-align: center;
  font-size: 1.6rem;
  margin-bottom: 20px;
}
