/* Main container layout */
.top-lineups-container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  color: #fff; /* Change text color to white for contrast */
  font-family: 'Poppins', sans-serif;  /* Add modern font */
  align-items: stretch; /* Ensures all sections have equal height */
}

/* Lineups section */
.lineups-section, .leaderboard-section {
  flex: 1;
  margin-right: 20px;
  padding: 20px; /* Add padding */
  background: rgba(0, 0, 0, 0.6); /* Add transparent background for modern look */
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  height: 100%; /* Ensure the section fills the height of the container */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.leaderboard-section {
  margin-left: 20px; /* Margin for the leaderboard */
}

/* Individual lineup cards */
.lineup-card, .leaderboard-table {
  background-color: rgba(255, 255, 255, 0.1); /* Transparent look */
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease; /* Smooth transition */
}

.lineup-card:hover, .leaderboard-table tr:hover {
  background-color: rgba(255, 255, 255, 0.2); /* Slight hover effect */
}

/* Lineup card header */
.lineup-card h3 {
  font-size: 1.4rem;
  margin-bottom: 10px;
  color: #ffd700; /* Aris yellow */
}

/* Lineup card list */
.lineup-card ul {
  padding-left: 20px;
  list-style: none; /* Remove bullet points */
}

.lineup-card ul li, .leaderboard-table td {
  margin: 10px 0;
  padding: 10px;
  background: rgba(255, 255, 255, 0.1); /* Subtle highlight */
  border-radius: 5px;
  transition: background 0.3s ease;
}

.lineup-card ul li:hover {
  background: rgba(255, 255, 255, 0.2);
}

/* Lineup percentage text */
.lineup-users {
  font-size: 14px;
  color: #ddd; /* Lighter text for modern look */
  text-align: right;
  margin-top: 10px;
}

/* Leaderboard table */
.leaderboard-table {
  width: 100%;
  border-collapse: collapse;
  font-family: 'Roboto', sans-serif;
}

.leaderboard-table th, .leaderboard-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2); /* More subtle borders */
}

.leaderboard-table th {
  background-color: #ffd700; /* Keep Aris yellow */
  color: #333;
  text-transform: uppercase;
  font-weight: bold;
}

.leaderboard-table td {
  background-color: rgba(255, 255, 255, 0.1); /* More subtle background color */
  color: #fff; /* White text for contrast */
}

.leaderboard-table tr:hover {
  background-color: rgba(255, 255, 255, 0.2); /* Hover effect */
}

/* Ensure full height for all containers */
.lineups-section, .leaderboard-section {
  min-height: 100%; /* Ensures each section stretches to full height */
}

.soccer-field-container {
  position: relative;
  width: 100%;
  max-width: 800px;
  margin: auto;
}

.soccer-field {
  width: 100%;
  height: auto;
}

.player {
  position: absolute;
  transform: translate(-50%, -50%);
  text-align: center;
  background: none !important; /* Ensure no background is applied */
  border: none !important; /* Ensure no border is applied */
}

.player-photo {
  width: 40px;
  height: 40px;
  border-radius: 50%; /* Makes the image circular */
  border: none !important; /* Remove any border */
  background: none !important; /* Ensure no background */
  box-shadow: none !important; /* Remove any shadows */
}

.player-name1 {
  margin-top: 5px; /* Add spacing between the photo and name */
  font-size: 12px; /* Adjust font size as needed */
  color: yellow; /* Adjust color to fit your design */
  background: none; /* Ensure no background */
}


.position-GK { top: 88%; left: 50%; }
.position-CB1 { top: 70%; left: 25%; }
.position-CB2 { top: 70%; left: 50%; }
.position-CB3 { top: 70%; left: 75%; }
.position-LB { top: 74%; left: 17%; }
.position-RB { top: 76%; left: 83%; }
.position-CM1 { top: 55%; left: 35%; }
.position-CM2 { top: 55%; left: 50%; }
.position-CM3 { top: 55%; left: 65%; }
.position-LW { top: 30%; left: 15%; }
.position-RW { top: 30%; left: 85%; }
.position-ST { top: 20%; left: 50%; }


/* Modify or add positions as needed */


