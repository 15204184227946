.top-lineups-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    padding: 20px;
}

.lineups-section {
    flex: 1;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 15px;
    padding: 20px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    color: #f0f0f0;
}

.lineup-card {
    margin-bottom: 20px;
    padding: 15px;
    border-radius: 12px;
    background-color: #333;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
}

.lineup-card h3 {
    text-align: center;
    margin-bottom: 15px;
    color: #ffc107;
    font-size: 1.5rem;
}

.lineup-list {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.lineup-player {
    display: flex;
    align-items: center;
    justify-content: center; /* Center the content */
    background-color: #222;
    border-radius: 12px;
    padding: 10px;
    transition: background-color 0.3s;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.lineup-player:hover {
    background-color: #444;
}

.player-photo {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 15px;
    object-fit: cover;
    border: 2px solid #ffc107;
}

.player-info {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center-align the text */
    text-align: center;
}

.player-name {
    font-size: 1rem;
    font-weight: bold;
    color: #fff;
}

.player-position {
    font-size: 0.9rem;
    color: #ffc107;
    font-weight: bold;
}

.lineup-users {
    text-align: center;
    margin-top: 10px;
    font-size: 0.95rem;
    color: #f0f0f0;
    font-style: italic;
}
